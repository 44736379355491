/* Add any other global styles you have */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  z-index: 100099999;
  /* Ensure the overlay is above other content */
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: min(680px, 96%);
  max-height: 90vh;
  z-index: 10019999999;
  overflow: auto;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalTitle {
  font-size: 18px;
  font-weight: bold;
  color: #9b102c;
  font-family: Tajawal;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.exitModal {
  cursor: pointer;
}

.exitModal svg {
  font-size: 30px;
  color: red;
}

.modalChildren {
  /* Add any styles you want for the modal content */
}

@media (max-width: 565px) {
  .modalChildren .container {
    flex-wrap: wrap;
  }

  .modalChildren .container .product-image {
    width: 100%;
  }
}