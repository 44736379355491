/* Main Container */
.roquia_details_page {
  padding: 20px; 
  background-color: #f9f9f9; /* Light background */
  min-height: 100vh; /* Full height */
}

/* Details Section */
.roquia_details {
  background-color: #ffffff; /* White background for details */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px; /* Padding around content */
  margin: auto; /* Center alignment */
  max-width: 800px; /* Maximum width */
}

/* Reader Name */
.roquia_details h4 {
  color: #9B102C; /* Main red color */
  font-size: 24px; /* Font size */
  margin-bottom: 15px; /* Space below */
}

/* Reader Image */
.img_reader {
  text-align: center; /* Center align the image */
  margin-bottom: 20px; /* Space below the image */
}

.img_reader img {
  width: 150px; /* Fixed width for the reader image */
  height: 150px !important; /* Fixed width for the reader image */
  height: auto; /* Auto height */
  border-radius: 50%; /* Circular image */
  border: 2px solid #9B102C; /* Border color */
  object-fit: contain;
}

/* Content Section */
.content {
  margin-bottom: 20px; /* Space below the content */
}

/* Title */
.content h5 {
  color: #333; /* Darker color for text */
  font-size: 20px; /* Font size */
  margin-bottom: 15px; /* Space below */
}

/* Audio Player */
audio {
  width: 100%; /* Full width for audio */
  margin: 10px 0; /* Space above and below */
}

/* Video Section */
iframe {
  border: none; /* No border */
  border-radius: 8px; /* Rounded corners */
}

/* Social Actions */
.target_social {
  display: flex; /* Flex layout */
  justify-content: space-between; /* Space between items */
  align-items: center; /* Center align items */
  margin-top: 20px; /* Space above */
}

.target_social h2 {
  font-size: 22px; /* Font size */
  color: #9B102C; /* Main red color */
}

.target_social img {
  cursor: pointer; /* Pointer cursor */
}

/* Loader */
.loader {
  display: flex; /* Flex layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80vh; /* Full height */
}
