.profile_page {
  padding: 20px;
  min-height: 100vh;
}

.profile_page .settings {
  width: 500px;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  border-radius: 10px;
  max-width: 98%;

}

.font_slider_div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.roquias_person {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.profile_content>div {
  margin-top: 30px;
}

.profile_content>div>h5 {
  color: var(--main-red-color);
  text-align: center;
}

.roquias_person>div {
  width: 48%;
}

.roquias_person .litany_one {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.roquias_person>div>h5 {
  font-weight: 900;
  color: #404040;
}

.litany_person {
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.litany_person>div {
  width: 48%;
}

.litany_person .litany {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.litany_person h5 {
  color: #404040;
  font-weight: 800;
}

.litany_person .litany h4 {
  color: var(--main-red-color);
  font-weight: 700;
}

.litany_person .litany p {
  color: #444;
  font-size: 18px !important;
}

@media (max-width:565px) {
  .litany_person>div {
    width: 90%;
    margin: auto;
  }

  .roquias_person>div {
    width: 90%;
    margin: auto;
  }
}

.profile_page .hadiths>div {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.profile_page .hadiths>div>div {
  width: 48%;
}

.profile_page .hadiths>div>div>div {
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 10px;
  border-radius: 10px;
}