.hadith_page {
  padding: 20px;
  background-color: #40404034;
  /* background-color: var(--main-light-primary-color); */
}

.hadith_page .imams {
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 4fr));
}

.hadith_page>h5 {
  color: var(--main-red-color);
  font-size: 30px;
}

.hadith_page .imams .imam {
  background-color: white;
  padding: 10px;
  font-size: 22px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: .3s all;
  border: 3px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hadith_page .imams .imam.active {
  background-color: var(--main-red-color);
  color: white;
  border: 3px solid white;
}

.hadiths_content {
  margin: 30px 0px;
}

.hadiths_content>h5 {
  color: var(--main-red-color);
}

.hadiths_content .hadiths {
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 4fr));
}

.hadith_item {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 380px;
  padding: 10px;
  background-size: cover;
  color: #f1f1f1;
  /* padding-top: 200px; */
  background-repeat: no-repeat;
  border-radius: 10px;
  position: relative;
  max-width: 100%;
  width: 330px;
  margin: auto;
  background-image: url("../../assets/images/Untitled\ design\ \(58\).png");
}

.hadith_item>div {
  width: 80%;
  margin: auto;
  position: relative;
  top: 90px;
  right:-16px;
}

.ellipsis {
  cursor: pointer;
}

.description p {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 200px;
  white-space: break-spaces;
  width: 100%;
  -webkit-line-clamp: 1;
}

.hadith_item h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.hadith_item p {
  font-size: 15px;
  font-weight: 800;
}

.pages {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.pages p {
  width: 30px;
  height: 30px;
  background-color: #dedede;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0px;
}

.pages p.active {
  background-color: #49a2c4;
  color: white;
}

.pages svg {
  cursor: pointer;
}

@media (max-width:670px) {
  .hadiths_content .hadiths {
    display: grid;
    gap: 20px 30px;
    grid-template-columns: repeat(auto-fill, minmax(48%, 4fr));
    display: flex !important;
    width: 100%;
    flex-wrap: wrap;
  }

  .hadith_item {
    width: 55%;
    margin: auto;
  }

  .hadith_item>div {
    bottom: 50px;
  }
}

@media (max-width:565px) {
  .hadith_item {
    width: 70%;
  }


}

@media (max-width:480px) {
  .hadith_item {
    width: 100%;
  }

  /* .description p {
    bottom: 30px !important;
  } */
  .hadith_item>div {
    bottom: 10px;
  }
}

.search_div {
  position: relative;
  width: 500px;
  max-width: 100%;
  /* margin: auto; */
}

.search_div svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.search_div {
  position: relative;
  max-width: 600px; /* Responsive width */
  margin: 0 auto; /* Center search bar */
}

.search_div input {
  padding: 10px 40px; /* Adjust padding for search input */
  border-radius: 25px; /* Rounded corners */
  border: 1px solid #ccc; /* Border styling */
  font-size: 16px; /* Font size */
}

.search_div svg {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%); /* Center icon vertically */
  color: #9b102c; /* Color for the search icon */
}
