/* Main Container */
.litany_details_page {
  padding: 20px;
  background-color: #f3f4f6; /* Light background for contrast */
  min-height: 100vh; /* Full height */
}

/* Details Section */
.litany_details {
  background-color: #ffffff; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px; /* Padding around content */
  margin: auto; /* Center alignment */
  max-width: 800px; /* Maximum width */
}

/* Video and Audio Containers */
.video_container, .audio_container {
  margin-bottom: 20px; /* Space below */
}

/* Title */
.details h5 {
  color: #9B102C; /* Main red color */
  font-size: 24px; /* Font size */
  margin-bottom: 10px; /* Space below */
}

/* Description */
.details p {
  color: #333; /* Darker color for text */
  font-size: 16px; /* Font size */
  margin-bottom: 15px; /* Space below */
}

/* Social Actions */
.target_social {
  display: flex; /* Flex layout */
  justify-content: space-between; /* Space between items */
  align-items: center; /* Center align items */
  margin-top: 20px; /* Space above */
}

.target_social h2 {
  font-size: 22px; /* Font size */
  color: #9B102C; /* Main red color */
}

/* Loader */
.loader {
  display: flex; /* Flex layout */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 80vh; /* Full height */
}

/* Buttons and Icons */
img {
  cursor: pointer; /* Pointer cursor for images */
  transition: transform 0.3s; /* Smooth transition for hover effect */
}

img:hover {
  transform: scale(1.1); /* Scale effect on hover */
}
