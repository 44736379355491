@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;500;900&family=Tajawal:wght@300;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri+Quran&display=swap');

@font-face {
  font-family: "Naskh";
  src: url('https://www.islambook.com/files/fonts/uthmantn.eot');
  /* IE9 Compat Modes */
  src: local('KFGQPC Uthman Taha Naskh'), url('https://www.islambook.com/files/fonts/uthmantn.eot?iefix') format('eot'),
    /* IE6-IE8 */
    url('https://www.islambook.com/files/fonts/uthmantn.otf') format('opentype'), url('https://www.islambook.com/files/fonts/uthmantn.woff') format('woff'),
    /* Modern Browsers */
    url('https://www.islambook.com/files/fonts/uthmantn.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('https://www.islambook.com/files/fonts/uthmantn.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

*{
}

body {
  direction: rtl;
  /* // resize the background-image */
  background-image: url('../public/svg/background-pattern.svg');
  background-repeat: repeat;
  background-size: 50%;
  background-position: center;
  background-attachment: fixed;
}



a {
  text-decoration: none;
  color: inherit;
}

.cards {
  margin: 40px auto 0 auto;
}

@media (min-width: 600px) {
  .cards {
    display: flex;
    flex-wrap: wrap;
  }
}

.card {
  background: #fff;
  flex-basis: 30%;
  max-width: 600px;
  min-height: 400px;
  border-radius: 0.6em;
  margin: 1em;
  overflow: hidden;
  cursor: pointer;
  box-sizing: border-box;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  flex: 1;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12),
    0 8px 32px -8px hsla(0, 0%, 0%, 0.14),
    0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.card img {
  width: 100%;
  object-fit: cover;
}

.card h2 {
  color: #222;
  margin-top: -0.2em;
  line-height: 1.4;
  font-size: 1.3em;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  transition: all ease-in 100ms;
}

.card p {
  color: #777;
  text-align: center;
}

.card h5 {
  color: #bbb;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  margin: 1.4em 0 0 0;
  text-transform: uppercase;
}

.card-body {
  padding: 1.2em;
}

.mat_list {
  list-style-type: none;
  display: block;
  height: 100%;
}

.mat_card {
  background-color: #fff;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 0.1rem rgba(0, 0, 0, 0.3),
    0px 0.2rem 0.2rem rgba(0, 0, 0, 0.3);
  overflow: auto;
}

.mat_list {
  list-style-type: none;
  display: block;
  height: 100%;
}

.mat_list img {
  width: 100%;
  object-fit: cover;
}

.mat_list>li {
  /* padding: 0.5rem; */
  display: block;
  font-size: 1.5rem;
  margin-top: -1px;
  width: 100%;
  max-height: 100%;
  transition: max-height 0.5s;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  text-align: right;
  /* color: #d39933; */
}

.mat_list>li:nth-of-type(2) {
  text-align: center;
  color: #d39933;
}

.mat_list>li::before {
  content: "";
  width: 75%;
  display: block;
  top: -10px !important;
  position: absolute;
  height: 2px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.mat_list>li.hide {
  max-height: 0px;
}

.mat_list>li strong {
  /* color: #777; */
}

.mat_list>li time {
  color: #777;
}

.center_vertical {
  display: flex;
  justify-content: center;
  align-items: center;
}



.pages_links {
  flex-wrap: wrap !important;
  grid-template-columns: repeat(auto-fill, minmax(140px, auto)) !important;
}

.page.page_know p {
  font-size: 15px !important;
  /* width: 200px !important;
  margin: auto; */
}