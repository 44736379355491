.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-button {
  width: 46px;
  height: 46px;
  border-radius: 4px !important;
  background: #eee;
  margin: 0 8px;
}

.pagination-button:hover {
  background-color: #f0f0f0;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: var(--main-red-color);
  color: #fff;
}