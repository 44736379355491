.hadith_page {
  padding: 20px;
  background-color: #f8f9fa; /* Light background color for better readability */
  font-family: "Arial", sans-serif; /* Use a clean font */
}

.hadith_content {
  background-color: white; /* White background for content area */
  padding: 20px;
  border-radius: 8px; /* Rounded corners for the content area */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.hadith_title {
  font-size: 24px; /* Larger font size for the hadith */
  color: #333; /* Darker text color */
  margin-bottom: 20px; /* Margin below title */
}

.loading {
  display: flex;
  justify-content: center; /* Center the loader */
  align-items: center;
  height: 80vh; /* Full height for loading */
}

.social_buttons {
  display: flex; /* Horizontal layout for buttons */
  flex-direction: column; /* Stack buttons vertically */
  align-items: flex-start; /* Align buttons to the start */
}

.share_buttons {
  display: flex;
  align-items: center; /* Center icons vertically */
  gap: 15px; /* Space between buttons */
}

.like_button {
  background: transparent; /* Transparent background */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
}

.share_icon,
.like_button img,
.makeStyles-iconContainer-43 svg,
.share_buttons div > div:first-child,
.share_buttons div,
[class^="makeStyles-container-"],
[class^="makeStyles-copyContainer-"] {
  width: 40px !important; /* Icon size */
}
[class^="makeStyles-copyContainer-"] {
  display: none !important;
}
h2 {
  margin: 0; /* Remove default margin */
  font-size: 20px; /* Font size for the commands title */
  color: #9b102c; /* Custom color for the title */
}

.share_buttons div > div:last-child {
  display: none !important;
}
